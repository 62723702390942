@import "./common.scss";

/* styles.css */
body {
  overflow-x: hidden;
}

.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  transition: transform 0.3s ease;
  z-index: 1000;
  // overflow-y: auto;

  .scrollable_sec {
    overflow-y: auto;
    height: calc(100vh - 50px);
  }

  li {
    > a {
      &.active {
        background-color: $mainColor !important;
        color: #f2f2f2 !important;
      }
      &:hover {
        background-color: $mainColor !important;
        color: #f2f2f2 !important;
      }
    }
  }
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar {
  transform: translateX(-100%);
}

.childrenContent {
  margin-left: 250px;
  width: calc(100% - 250px);
  overflow-y: auto;
  height: 100vh;
}

// .childrenContent .btn {
//   margin-left: -250px; /* Adjust this value if the sidebar width changes */
//   z-index: 1100;
// }

@media (max-width: 767px) {
  .sidebar {
    width: 200px;
  }

  .childrenContent {
    margin-left: 200px;
    width: calc(100% - 200px);
  }

  // .childrenContent .btn {
  //   margin-left: -200px;
  // }
}
