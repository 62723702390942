/* PageTitle.scss */
.page-title-container {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .page-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 2px solid;
  }
  
  .page-subtitle {
    font-size: 1.25rem;
    color: #6c757d;
  }
  