@import "./common.scss";

.update_designation {
  position: fixed;
  top: 50%;
  left: -100%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  z-index: 1000;
  transition: 0.4s;

  &.show_modal {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
