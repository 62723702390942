@import "./common.scss";

.customTable{
  border-collapse: collapse;
  white-space: nowrap;
    
  th{
    background-color: $mainColor;
    color: #f2f2f2;
  }
}