@import "./common.scss";

.updates_main {
  .last_messages {
    ol {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      
      li {
        padding: 15px;
        box-sizing: border-box;
        background-color: rgba(255, 50, 148, 0.6);
        border-radius: 20px;
        p {
          margin: 0;
          &:first-child {
            span {
              &:first-child {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
