@import "./common.scss";

.AddPolicy_main {
.form-select{
    background-color: transparent!important;
    padding: 0 40px 0 0!important;
    select{
        button{
            z-index: 999;
        }
    }
}
}
