@import "./common.scss";

.dashboard_main {
  .custom-card {
    // box-shadow: 1px 1px 1px 1px black;
    button{
        border-radius: 0 0.375rem 0 0.375rem;
        box-shadow: 1px 1px 1px 1px black;
        &:hover{
            --bs-bg-opacity: 1;
            background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
            color: #f2f2f2;
            box-shadow: 1px 1px 1px 1px black;
        }
    }
  }
}
