@import "./common.scss";

.pagination_sec {
  margin: 40px auto 0;

  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    > li {
      font-size: 16px;
      padding: 5px;
      border: 1px solid #f2f2f2;
      min-width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.selected {
        background-color: #f2f2f2;
        color: $mainColor;
      }
    }
  }
}
